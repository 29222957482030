<template>
  <div>
    <div class="row mb-3 justify-content-between" v-if="search">
      <div class="col my-auto d-flex mb-2">
        <input
          type="text"
          v-model="searchStr"
          class="form-control form-control d-inline"
          :placeholder="searchLabel"
          @input="performSearch"
        />

        <button
          class="btn btn btn-light d-inline ms-2"
          v-tooltip.bottom="'Clear search'"
          @click="clearSearch"
        >
          <i class="far fa-times"></i>
        </button>
      </div>
      <div class="col" v-if="filterByTag">
        <multiselect
          v-model="tagFilter"
          :options="tags"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :placeholder="$t('views.tables.datalist.chooseYourTags')"
          @input="performSearch"
        ></multiselect>
      </div>
    </div>

    <div class="card bg-light border-0 shadow-none mb-3">
      <div class="card-body">
        <div class="py-2" v-if="!hideHeaders">
          <div class="row fw-bold">
            <div class="col-auto" v-if="selectable">
              <div
                class="
                  h-100
                  w-100
                  d-flex
                  justify-content-center
                  align-items-center
                "
                @click="toggleAllResults"
              >
                <div>
                  <i
                    class="far fa-2x"
                    :class="
                      allSelected
                        ? 'fa-check-square text-secondary'
                        : 'fa-square text-dark'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <template v-for="f in fields">
              <div
                class="col"
                :class="[f.class_list, f.hide_sm ? 'd-none d-md-block' : '']"
              >
                <h5
                  v-if="!f.hide_header"
                  :class="{ 'cursor-pointer': f.sortable }"
                  @click="sortBy(f.field_name)"
                >
                  {{ f.display_name }}
                  <i
                    v-if="f.sortable"
                    class="far ms-1"
                    :class="
                      sort === f.field_name
                        ? 'text-primary fa-sort-' +
                          (sortDirection === 'asc' ? 'up' : 'down')
                        : 'fa-sort'
                    "
                  />
                </h5>
              </div>
            </template>
          </div>
        </div>

        <busy v-if="loading" class="my-auto" />
        <div v-else-if="results.length === 0" class="text-center">
          <i class="fal fa-search fa-3x moving-gradient-text mb-3" />
          <h5 class="fw-normal">No {{ this.displayName | pluralize }}</h5>
        </div>
        <div v-else>
          <div class="row" v-for="r in results" :key="r.id">
            <div class="col-auto" v-if="selectable">
              <div
                class="
                  h-100
                  w-100
                  d-flex
                  justify-content-center
                  align-items-center
                "
                @click="toggleResult(r)"
              >
                <div>
                  <i
                    class="far fa-2x"
                    :class="
                      resultIncluded(r)
                        ? 'fa-check-square text-secondary'
                        : 'fa-square text-dark'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="bg-white rounded shadow-sm mb-3 px-4 py-3"
                :class="{ 'cursor-pointer crank-table-row-link': !dontLink }"
                @click="navigateTo(r)"
              >
                <div class="row">
                  <div
                    class="col my-auto"
                    v-for="f in fields"
                    :class="[
                      f.class_list,
                      f.highlight ? 'text-primary fw-bold' : '',
                      f.hide_sm ? 'd-none d-md-block' : '',
                      f.capitalize ? 'text-capitalize' : '',
                    ]"
                  >
                    <img
                      v-if="f.image && getFieldData(r, f.image.field_name)"
                      :src="getFieldData(r, f.image.field_name)"
                      alt="contact"
                      :width="f.image.width ? f.image.width : 32"
                      :height="f.image.height ? f.image.height : 32"
                      :class="f.image.rounded ? 'rounded-circle' : ''"
                      class="me-2"
                    />

                    <span v-if="f.prefix">{{ f.prefix }}</span>

                    <span v-if="f.type === 'text'">
                      {{ getFieldData(r, f.field_name) }}
                    </span>
                    <span v-else-if="f.type === 'date'">
                      {{ getFieldData(r, f.field_name) | formatDateNoTime }}
                    </span>
                    <span v-else-if="f.type === 'datetime'">
                      {{ getFieldData(r, f.field_name) | formatDate }}
                    </span>
                    <span v-else-if="f.type === 'date_relative'">
                      {{ getFieldData(r, f.field_name) | relative }}
                    </span>
                    <span v-else-if="f.type === 'currency'">
                      {{ r.currency.symbol
                      }}{{ (getFieldData(r, f.field_name) / 100).toFixed(2) }}
                    </span>
                    <span v-else-if="f.type === 'model'">
                      {{ getFieldData(r, f.field_name) | stripNamespace }}
                    </span>
                    <span v-else-if="f.type === 'db_icon'">
                      <i class="fad" :class="getFieldData(r, f.field_name)" />
                    </span>
                    <span v-else-if="f.type === 'static_icon'">
                      <i class="fad" :class="f.static_icon" />
                    </span>
                    <span v-else-if="f.type === 'icon'">
                      <i :class="f.icon_data[getFieldData(r, f.field_name)]" />
                    </span>
                    <span v-else-if="f.type === 'badge'">
                      <span
                        class="badge"
                        :class="f.badge_data[getFieldData(r, f.field_name)]"
                      >
                        {{ getFieldData(r, f.field_name) }}
                      </span>
                    </span>
                    <span v-else-if="f.type === 'html'">
                      {{ getFieldData(r, f.field_name) | htmlPreview }}
                    </span>

                    <span v-if="!getFieldData(r, f.field_name) && f.null_value">
                      {{ f.null_value }}
                    </span>

                    <span v-if="f.suffix">{{ f.suffix }}</span>
                  </div>
                  <div
                    class="col-auto text-end d-none d-md-block"
                    v-if="allowDelete"
                  >
                    <button class="btn btn-light" @click.stop="deleteRecord(r)">
                      <i class="far fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <pagination v-if="results.length > 0" :pagination="pagination" @navigate="fetchData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import Busy from "@/components/Busy";
import Pagination from "@/views/components/tables/Pagination";
import Multiselect from "vue-multiselect";

export default {
  components: { Pagination, Busy, Multiselect },
  props: [
    "apiResource", // API resource identifier
    "displayName", // Model display name (non-plural)
    "fields", // Fields to display in data table
    "search", // Enable search boolean
    "defaultSort", // Default sort field
    "defaultSortDirection", // Default sort direction
    "hideHeaders", //Hide table headers
    "allowDelete", // Show delete button
    "dontLink", // Prevent items being clickable,
    "selectable",
    "filterByTag",
    "searchLabel"
  ],
  data() {
    return {
      loading: true,
      searchStr: "",
      sort: this.defaultSort,
      sortDirection: this.defaultSortDirection ?? "desc",
      pagination: {},
      results: [],
      tagFilter: null,
      tags: [],
      selectedResults: []
    };
  },
  watch: {
    selectedResults: {
      handler: function(val) {
        this.$emit("selected", val);
      }
    }
  },
  computed: {
    allSelected() {
      return this.selectedResults.length === this.results.length;
    }
  },
  methods: {
    toggleAllResults() {
      if (this.selectedResults.length == this.results.length) {
        this.selectedResults = [];
      } else {
        this.selectedResults = [];
        this.results.forEach(r => {
          this.selectedResults.push(r);
        });
      }
    },
    toggleResult(result) {
      if (!this.selectedResults.includes(result)) {
        this.selectedResults.push(result);
      } else {
        let index = this.selectedResults
          .map(x => {
            return x.id;
          })
          .indexOf(result.id);

        this.selectedResults.splice(index, 1);
      }
    },
    resultIncluded(result) {
      return this.selectedResults.includes(result);
    },
    fetchData(paginationUrl) {
      let url = paginationUrl ?? this.apiResource;

      url = this.updateQueryStringParameter(url, "paginate", true);

      if (this.sort) {
        url = this.updateQueryStringParameter(url, "sort", this.sort);
      }

      if (this.tagFilter) {
        url = this.updateQueryStringParameter(
          url,
          "tags",
          this.tagFilter.join(",")
        );
      }

      if (this.sortDirection) {
        url = this.updateQueryStringParameter(
          url,
          "sort_direction",
          this.sortDirection
        );
      }

      if (this.searchStr) {
        url = this.updateQueryStringParameter(url, "search", this.searchStr);
      }

      this.$axios.get(url).then(({ data }) => {
        this.pagination = data;
        this.results = data.data;
        this.loading = false;
      });
    },
    getFieldData(row, key) {
      if (key.includes(".")) {
        const keys = key.split(".");

        let ret = row;

        for (let key of keys) {
          if (ret[key]) {
            ret = ret[key];
          } else {
            ret = "";
            break;
          }
        }

        return ret;
      }

      return row[key];
    },
    deleteRecord(record) {
      this.$confirm({
        message:
          "Are you sure you wish to remove this " +
          this.displayName +
          " record?",
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.$axios
              .delete(
                process.env.VUE_APP_API_URL + this.apiResource + "/" + record.id
              )
              .then(({ data }) => {
                this.$EventBus.$emit("alert", data);
                this.fetchData();
              });
          }
        }
      });
    },
    navigateTo(result) {
      this.$emit("navigateTo", result);
    },
    clearSearch() {
      this.searchStr = "";
      this.fetchData();
    },
    performSearch: debounce(function() {
      this.fetchData();
    }, 300),
    sortBy(f) {
      if (f.sortable) {
        if (this.sort === f.field_name) {
          this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
        } else {
          this.sort = f.field_name;
        }
        this.fetchData();
      }
    },
    async fetchTags() {
      const { data } = await this.$axios.get("/team/tags");

      this.tags = data;
    }
  },
  filters: {
    stripNamespace(str) {
      if (str) {
        return str.replace("App\\Models\\", "");
      }
      return str;
    },
    htmlPreview(str) {
      if (str) {
        return (
          str.replace(/<[^>]*>?/gm, "").substring(0, 45) +
          (str.length > 45 ? "..." : "")
        );
      }
      return str;
    },
    relative(date) {
      if (date) {
        return moment(date)
          .local()
          .locale("en-short")
          .fromNow();
      }
      return date;
    }
  },
  created() {
    this.fetchData();
    this.fetchTags();
    this.$EventBus.$on("updateDataList", this.fetchData);
  },
  destroyed() {
    this.$EventBus.$off("updateDataList", this.fetchData);
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__tag,
.multiselect__option--highlight {
  background: #6c4a9e;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #9764e4;
}
</style>
