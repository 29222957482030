<template>
  <div>
    <div class="container" v-if="list && clients">
      <div class="row mb-2">
        <div class="col-auto ms-auto">
          <button
            class="btn btn-outline-secondary btn-sm"
            @click="$router.back()"
          >
            <i class="far fa-arrow-left me-2"></i>Back
          </button>
        </div>
      </div>
      <h2
        class="text-primary fw-bold"
        v-if="!editing"
        @click="editing = true"
        v-tooltip.left="'Click to edit'"
      >
        {{ list.name }}
      </h2>

      <input
        type="text"
        class="form-control form-control-lg mb-4"
        v-if="editing"
        v-model="list.name"
        @change="updateList"
        autofocus
      />

      <p class="" v-if="list.description">{{ list.description }}</p>
      <hr class="my-4" />
      <input
        type="text"
        class="form-control"
        v-model="search"
        @input="searchClients"
        :placeholder="'Search for clients'"
      />
      <div class="position-relative bg-white mb-4">
        <div
          v-if="results.length > 0"
          class="position-absolute bg-white"
          style="z-index: 9999; min-height: 300px; overflow: scroll"
        >
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              v-for="r in results"
              :key="r.id"
              @click="addContact(r)"
            >
              {{ r.name }} / {{ r.email }}
            </li>
          </ul>
        </div>
      </div>

      <div class="card border-0 shadow-none bg-light">
        <div class="card-body p-4">
          <div v-if="clients.length > 0">
            <ul class="list-group list-group-flush mb-3">
              <li class="list-group-item" v-for="c in clients" :key="c.id">
                <div class="row">
                  <div class="col my-auto">
                    <p class="mb-0">{{ c.name }}</p>
                  </div>
                  <div class="col my-auto">
                    <p class="mb-0">{{ c.email }}</p>
                  </div>
                  <div class="col-auto my-auto">
                    <button
                      @click="$router.push('/clients/' + c.id)"
                      class="btn btn-outline-secondary btn-sm me-3"
                    >
                      <i class="far fa-eye me-2"></i>View
                    </button>

                    <button
                      @click="removeContact(c)"
                      class="btn btn-outline-danger btn-sm"
                    >
                      <i class="far fa-trash me-2"></i>Remove
                    </button>
                  </div>
                </div>
              </li>
            </ul>

            <pagination :pagination="pagination" @navigate="fetchListClients" />
          </div>

          <div v-else class="d-flex justify-content-center align-items-center">
            <div class="text-center">
              <div>
                <i class="far fa-search fa-5x text-secondary"></i>
              </div>
              <p class="fw-bold mt-4">No one on the list yet!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "@/views/components/loader/Loading";
import DataList from "@/views/components/tables/DataList";
import Pagination from "@/views/components/tables/Pagination";

export default {
  data() {
    return {
      list: null,
      clients: [],
      pagination: {},
      search: "",
      results: [],
      editing: false,
    };
  },
  methods: {
    async searchClients() {
      if (this.search.length > 1) {
        const { data } = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/clients/search-by-name",
          {
            search_term: this.search,
            show_inactive: true,
          }
        );
        this.results = data;
      } else {
        this.results = [];
      }
    },
    async removeContact(client) {
      const { data } = await this.$axios.put(
        process.env.VUE_APP_API_URL +
          "/marketing-lists/" +
          this.list.id +
          "/remove-client",
        { id: client.id }
      );

      this.fetchListClients();
      this.$EventBus.$emit("alert", { message: data.message });
    },
    async addContact(client) {
      const { data } = await this.$axios.put(
        process.env.VUE_APP_API_URL +
          "/marketing-lists/" +
          this.list.id +
          "/add-client",
        { id: client.id }
      );

      this.search = "";
      this.results = [];

      this.fetchListClients();
      this.$EventBus.$emit("alert", { message: data.message });
    },
    async fetchList() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/marketing-lists/" +
          this.$route.params.id
      );
      this.list = data;
    },
    async fetchListClients(paginationUrl) {
      const url =
        paginationUrl ??
        "/marketing-lists/" + this.$route.params.id + "/clients";
      const { data } = await this.$axios.get(process.env.VUE_APP_API_URL + url);
      this.pagination = data;
      this.clients = data.data;
    },
    async updateList() {
      const { data } = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/marketing-lists/" + this.list.id,
        this.list
      );
      this.editing = false;
      this.$EventBus.$emit("alert", { message: data.message });
    },
  },
  created() {
    this.fetchList();
    this.fetchListClients();
  },
  components: {
    Pagination,
    DataList,
    Loading,
  },
};
</script>

<style>
</style>
