<template>
  <div class="row">
    <div class="col my-auto">
      <button
        class="btn btn-sm btn-light"
        @click="navigate(pagination.first_page_url)"
        :disabled="!pagination.first_page_url"
      >
        <i class="far fa-chevron-double-left" />
      </button>

      <button
        class="btn btn-sm btn-light ms-3"
        @click="navigate(pagination.prev_page_url)"
        :disabled="!pagination.prev_page_url"
      >
        <i class="far fa-chevron-left" />
      </button>
    </div>
    <div class="col my-auto text-center">
      <p class="mb-0">
        {{ "Page " + pagination.current_page + " / " + pagination.last_page }}
      </p>
    </div>
    <div class="col my-auto text-end">
      <button
        class="btn btn-sm btn-light cursor-pointer me-3"
        @click="navigate(pagination.next_page_url)"
        :disabled="!pagination.next_page_url"
      >
        <i class="far fa-chevron-right" />
      </button>

      <button
        class="btn btn-sm btn-light cursor-pointer"
        @click="navigate(pagination.last_page_url)"
        :disabled="!pagination.last_page_url"
      >
        <i class="far fa-chevron-double-right" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pagination"],
  methods: {
    navigate(url) {
      this.$emit("navigate", url);
    },
  },
};
</script>

<style>
</style>
